<template>
  <div class="previewImg">
    <el-dialog v-model="imgVisible" top="60px" width="100%">
      <img class="curImg" :src="curImg" alt="">
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    curImg: String,
  },
  data() {
    return {
      imgVisible: false,
    };
  },
  methods: {
    init() {
      this.imgVisible = true;
    },
  },
};
</script>

<style lang="scss">
.previewImg {
  .el-dialog{
    height: calc(100vh - 60px);
    margin: 0;
    background-color: rgba(0, 0, 0, 0);
  }
  .el-dialog__headerbtn{
    width: 56px;
    height: 56px;
    background-color: #333B50;
    top: 0;
    right: 0;
    i{
      font-size: 30px;
      color: #fff;
    }
  }
  .el-dialog__header{
    margin: 0;
    padding: 0;
  }
  .el-dialog__body{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
