<template>
  <div @click="handleClick" class="backBtn">
    <img src="../assets/img/back.png" alt="">
    返回
  </div>
</template>

<script>
import Router from '../router/index';

export default {
  props: {
    handleClick: {
      type: Function,
      default: () => Router.back(),
    },
  },
};
</script>

<style lang="scss" scoped>
.backBtn{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  line-height: 40px;
  color: #A0A2AB;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 2px 10px 0px rgba(120, 154, 172, 0.18);
  cursor: pointer;
  img{
    width: 8px;
    margin-right: 10px;
  }
}
</style>
