import momment from "moment-timezone"
export const initAgreement = (str, obj) => {
    if (!str) { return str; }
    const { invoiceHead, orderCode, payerName, itemName, invoicePrice, price, createDate, flowId, taxpayerIdentificationNum, email } = obj || {}
    return str.replace(/--hcp--page--/g, "")
        .replace("****userName****", payerName || "")
        .replace("****orderCode****", orderCode || "")
        .replace("****price****", price || "")
        .replace("****itemName****", itemName || "")
        .replace("****invoicePrice****", invoicePrice || "")
        .replace("****createDate****", momment(createDate).format("YYYY-MM-DD HH:mm:ss"))
        .replace("****flowId****", flowId || "")
        .replace("****taxNum****", taxpayerIdentificationNum || "")
        .replace("****invoiceHead****", invoiceHead || "")
        .replace("****email****", email || "")
}

export const getDocumentKey = (catalog) => {
    // [4,14,16,17]//虎刺怕互联网服务（深圳）有限公司
    let num = Number(catalog);
    if ([4, 14, 16, 17].includes(num)) {//写作批改协议
        return "writingPurchaseAgreement"
    } else if ([0].includes(num)) {// 会员
        return "vipPurchaseAgreement"
    } else if ([7, 9, 10, 13, 18, 20, 22].includes(num)) {//口语点评
        return "oralCommentPurchaseAgreement"
    } else if ([2, 3, 21].includes(num)) {//课堂
        return "coursePurchaseAgreement"
    } else if ([5, 15].includes(num)) {//口语1v1
        return "oralPurchaseAgreement"
    } else {
        return ""
    }
};
