<template>
  <back-btn class="back_btn" :handle-click="() => $router.back()"></back-btn>
  <br />
  <div class="viewContainer">
    <div v-if="documentText">
      <h3>{{ title }}</h3>
      <div v-html="documentText" class="docRichText"></div>
      <div class="signSection">
        <div class="sectionItem">
          <p>
            购买方：<img class="signImage" :src="baseInfo.undoneImageUrl" />
          </p>
          <p>日期：{{ dateArr[0] }}年{{ dateArr[1] }}月{{ dateArr[2] }}日</p>
        </div>
        <div class="sectionItem">
          <p>服务提供方：{{ componeyName }}</p>
          <p>日期：{{ dateArr[0] }}年{{ dateArr[1] }}月{{ dateArr[2] }}日</p>
        </div>
      </div>
    </div>
    <div id="Pdf" class="pdfViewer"></div>
    <div
      v-for="(item, index) in imgs"
      :key="index"
      class="imgContainer"
      @click="preview(item)"
    >
      <img :src="item" class="image" />
      <p class="previewTip"><i class="previewIcon"></i>点击查看大图</p>
    </div>
    <preview-img ref="previewImg" :cur-img="curImg" />
  </div>
</template>
<script>
import request from "@/utils/request";
import BackBtn from "@/components/BackBtn.vue";
import { initAgreement, getDocumentKey } from "@/views/userInvoices/util.js";
import moment from "moment-timezone";
import PreviewImg from "@/views/writing/components/PreviewImg.vue";

export default {
  components: { BackBtn, PreviewImg },
  data() {
    return {
      imgs: [],
      type: "",
      componeyName: "",
      documentText: "",
      title: "",
      baseInfo: {},
      dateArr: ["00", "00", "00"],
      curImg: "",
    };
  },
  created() {
    const { code, type, catalog } = this.$route.query;
    this.type = type;
    request("/hcp/trade/order/invoice/getByOrderCode", {
      method: "get",
      params: { orderCode: code, type },
    }).then((res) => {
      if (res?.finishImageUrl) {
        let arr = res?.finishImageUrl ? res?.finishImageUrl.split(",") : [];
        let pdfs = arr.filter(
          (item) => (item.endsWith(".pdf") || item.endsWith(".PDF")) && item
        );
        let reg = /http.*(.png|.jpg|.jpeg)$/gi;
        this.imgs = arr.filter((item) => reg.test(item) && item);
        if (pdfs.length) {
          this.showPDF(pdfs[0]);
        }
        return false;
      }
      if (type === "0") {
        const {
          orderCode,
          payerName,
          itemName,
          undoneImageUrl,
          createDate,
          flowId,
          invoicePrice,
          price,
          payTime,
          invoiceHead,
          email,
          taxpayerIdentificationNum,
        } = res || {};
        this.baseInfo = {
          invoiceHead,
          email,
          taxpayerIdentificationNum,
          orderCode,
          payerName,
          itemName,
          undoneImageUrl,
          createDate: payTime,
          flowId,
          invoicePrice,
          price,
        };
        this.getDocument(catalog, this.baseInfo);
        const dateStr = createDate
          ? moment(createDate).format("YYYY-MM-DD")
          : "";
        this.dateArr = dateStr ? dateStr.split("-") : ["00", "00", "00"];
      }
    });
  },
  methods: {
    showPDF(url) {
      try {
        // 缓存中的pdfUrl
        let PDFurl = url;
        if (PDFurl) {
          let Pdfh5 = require("pdfh5");
          //实例化
          this.pdfh5 = new Pdfh5("#Pdf", {
            pdfurl: PDFurl,
          });
        }
      } catch (e) {
        // Do something when catch error
      }
    },
    getDocument(catalog, obj) {
      const key = getDocumentKey(catalog);
      if (!key) {
        Taro.showToast({ title: "该类型商品没有相关协议", icon: "none" });
        return false;
      }
      request("/hcp/base/base/document/" + key, {
        method: "get",
      }).then((res) => {
        const { documentTopic, content, remark } = res || {};
        this.componeyName = remark;
        this.title = documentTopic;
        this.documentText = initAgreement(content, obj);
      });
    },
    preview(url) {
      this.curImg = url;
      this.$refs.previewImg.init();
    },
  },
};
</script>
<style lang="scss" scoped>
.viewContainer {
  width: 100%;
  height: calc(100vh - 200px);
  overflow: hidden;
  overflow-y: auto;
  min-height: 600px;
  background: #fff;
  box-sizing: border-box;
  padding: 50px 80px;
  h3 {
    text-align: center;
    margin-bottom: 20px;
  }

  .imgContainer {
    width: 500px;
    margin: 0 auto 10px;
    position: relative;
    cursor: pointer;
    .image {
      display: block;
      width: 500px;
      max-width: 100%;
    }
    .previewTip {
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      border-radius: 15px;

      position: absolute;
      right: 30px;
      top: 30px;
      z-index: 10;
      color: #fff;
      font-size: 12px;
      background: rgba(0, 0, 0, 0.6);
      .previewIcon {
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 16px;
        background: url("../../assets/img/preview_img.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .tip {
    text-align: center;
    color: #6d7278;
  }
  .signSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #333;
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 30px;
    .sectionItem {
      flex: 1;
    }
    .signImage {
      height: 28px;
      max-height: 100%;
      object-fit: contain;
    }
  }
}
</style>
<style lang="scss">
.viewContainer {
  .pdfViewer{
    width: 100%;
    img{
      max-width:100%;
    }
  }
  .docRichText {
    * {
      color: #333 !important;
      font-size: 14px !important;
      line-height: 28px !important;
      word-wrap: break-word;
      white-space: pre-wrap;
    }
  }
}
</style>
